import React, { useState } from "react";
import classNames from "classnames";
import styles from "./styles/MSDS.module.scss";
import { useTranslation } from "react-i18next";

import { AppLanguage } from "../Internationalization/i18n";
import { isharrisbruno } from "./Domain/checkharrisbruno";

const MSDS = ({ changeLang }: { changeLang: (lang: AppLanguage) => void }) => {
  const { t, i18n } = useTranslation();

  const [usedLang, setUsedLang] = useState(i18n.language.split("-")[0]);

  const [showSelectedLang, setShowSelectLang] = useState(false);
  const showLanguage = () => {
    setShowSelectLang((showSelectedLang) => !showSelectedLang);
  };

  const changeLangInternal = (lang: AppLanguage) => {
    setUsedLang(lang);
    i18n.changeLanguage(lang);
    changeLang(lang);
  };

  return (
    <>
      <div className={classNames(styles.zonemodal)} data-theme={"dark"}>
        <div className={classNames(styles.modalcachemobile)}></div>
        <div className={classNames(styles.contactsupportzone)}>
          <a
            href={
              process.env[
                isharrisbruno()
                  ? "REACT_APP_CONNECT_URL_HB"
                  : "REACT_APP_CONNECT_URL"
              ] + "support"
            }
            target="_blank"
            rel="noreferrer"
          >
            <p>{t("need_help")}</p>
          </a>
        </div>
        <div>
          <div className={classNames(styles.changelanguagezone)}>
            <button
              onClick={showLanguage}
              className={classNames(styles.changelanguage)}
            >
              {usedLang}
              <div
                className={classNames(
                  styles.button,
                  !showSelectedLang ? "" : styles.view
                )}
              >
                {usedLang === "fr" ? (
                  <p onClick={() => changeLangInternal("en")}>En</p>
                ) : (
                  <p onClick={() => changeLangInternal("fr")}>Fr</p>
                )}
              </div>
            </button>
          </div>
        </div>
        <div className={classNames(styles.modal)}>
          <div className={classNames(styles.buttondowloadzone)}>
            <a target="_blank" rel="noreferrer" href="/msds.pdf">
              {t("downloadmsds")}
            </a>
          </div>
          <div className={classNames(styles.buttondowloadzone)}>
            <a target="_blank" rel="noreferrer" href="/preconisation.pdf">
              {t("download")} <br /> {t("precoutil")}.
            </a>
          </div>
        </div>
      </div>
      <div
        data-theme={"dark"}
        className={classNames(
          styles.padding,
          styles.float_left,
          styles.heightbottom,
          "col-12"
        )}
      >
        <a target="_blank" rel="noreferrer" href={t("CGUlink")}>
          {t("CGU")}
        </a>
      </div>
    </>
  );
};

export default MSDS;
