import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import styles from "./styles/Modal.module.scss";
import logoconnecthb from "../images/logo/logoconnectmgihb.svg";
import logoconnectgriscomplet from "../images/logo/logoconnectmgi2.svg";
import { useTranslation } from "react-i18next";
import { useBackend } from "../backend/BackendProvider";
import { toast } from "react-toastify";
import { useAuth } from "@mgi-labs/mgi-id";
import { AppLanguage } from "../Internationalization/i18n";
import useOutsideClick from "./UseOutsideClick";
import { useParams, useSearchParams } from "react-router-dom";
import { mapAppToUrl } from "./Domain/redirectUrl";
import eye from "../images/icon/eye.svg";
import eyeClosed from "../images/icon/eye_closed.svg";
import logomgilittle from "../logo/logomgi.png";
import { countries, formCheckError } from "./Domain/createAccountDef";
import { NavLink } from "react-router-dom";
import { isharrisbruno } from "./Domain/checkharrisbruno";

const CreateAccount = ({
  changeLang,
}: {
  changeLang: (lang: AppLanguage) => void;
}) => {
  const { t, i18n } = useTranslation();

  const { groupId } = useParams<{
    groupId: string | undefined;
  }>();

  const [searchParams] = useSearchParams();

  const backend = useBackend();
  const { auth } = useAuth();

  const [email, setEmail] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [company, setCompany] = useState<string>();
  const [address, setAddress] = useState<string>();
  const [zipcode, setZipcode] = useState<string>();
  const [phone, setPhone] = useState<string>();
  const [mobile, setMobile] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [confirmPassword, setConfirmPassword] = useState<string>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [registered, setRegistered] = useState<
    "verified" | "verification" | undefined
  >();

  const [companyExist, setCompanyExist] = useState<boolean>(false);
  const [addressExist, setAddressExist] = useState<boolean>(false);
  const [postCodeExist, setPostCodeExist] = useState<boolean>(false);
  const [phoneExist, setPhoneExist] = useState<boolean>(false);
  const [mobilePhoneExist, setMobilePhoneExist] = useState<boolean>(false);
  const [countryExist, setCountryExist] = useState<boolean>(false);

  useEffect(() => {
    async function getInfo() {
      if (groupId) {
        const infoGroup = await backend.connexion.getInfoGroup(
          groupId,
          isharrisbruno() ? "HB" : "MGI"
        );

        setCompany(infoGroup.company);
        if (infoGroup.company) setCompanyExist(true);
        setAddress(infoGroup.address);
        if (infoGroup.address) setAddressExist(true);
        setZipcode(infoGroup.postCode);
        if (infoGroup.postCode) setPostCodeExist(true);
        setPhone(infoGroup.phone);
        if (infoGroup.phone) setPhoneExist(true);
        setMobile(infoGroup.mobilePhone);
        if (infoGroup.mobilePhone) setMobilePhoneExist(true);
        setSelectedCountry(infoGroup.country);
        if (infoGroup.country) setCountryExist(true);
      }
    }
    getInfo();
  }, [backend.connexion, groupId]);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const showPasswordCallback = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);

  const [selectedCountry, setSelectedCountry] = useState<string>();
  const lastNameRef = useRef<HTMLInputElement>(null!);
  const firstNameRef = useRef<HTMLInputElement>(null!);
  const emailRef = useRef<HTMLInputElement>(null!);
  const companyRef = useRef<HTMLInputElement>(null!);
  const addressRef = useRef<HTMLInputElement>(null!);
  const passwordRef = useRef<HTMLInputElement>(null!);
  const confirmPasswordRef = useRef<HTMLInputElement>(null!);
  const countryRef = useRef<HTMLInputElement>(null!);
  const zipcodeRef = useRef<HTMLInputElement>(null!);
  const phoneRef = useRef<HTMLInputElement>(null!);
  const mobileRef = useRef<HTMLInputElement>(null!);

  const handleCreateUser = useCallback(
    async (event: SyntheticEvent | KeyboardEvent) => {
      if (confirmPassword !== password) {
        event.preventDefault();
        passwordRef?.current.setCustomValidity(t("password_must_match"));
        passwordRef.current?.reportValidity();
        confirmPasswordRef?.current.setCustomValidity(t("password_must_match"));
        confirmPasswordRef.current?.reportValidity();
      }
      // check all form inputs
      formCheckError(event, "lastName", lastName, lastNameRef, t);
      formCheckError(event, "firstName", firstName, firstNameRef, t);
      formCheckError(event, "email", email, emailRef, t);
      formCheckError(event, "password", password, passwordRef, t);
      formCheckError(event, "company", company, companyRef, t);
      formCheckError(event, "zipcode", zipcode, zipcodeRef, t);
      formCheckError(event, "address", address, addressRef, t);
      formCheckError(event, "country", selectedCountry, countryRef, t);

      if (!phone && !mobile) {
        formCheckError(event, "phone", phone, phoneRef, t);
        formCheckError(event, "mobile", mobile, mobileRef, t);
      }

      if (
        email &&
        lastName &&
        firstName &&
        company &&
        zipcode &&
        address &&
        (phone || mobile) &&
        selectedCountry &&
        password &&
        password === confirmPassword &&
        password.length > 7
      ) {
        event.preventDefault();
        setIsLoading(true);
        const navLang = window.navigator.language.split("-")[0];

        const lang = i18n.language
          ? i18n.language.includes("fr")
            ? "fr"
            : "en"
          : navLang !== "fr" && navLang !== "en"
          ? "en"
          : navLang;
        try {
          const response = await backend.connexion.createNewUser(
            email,
            lastName,
            firstName,
            password,
            company,
            selectedCountry,
            address,
            zipcode,
            phone,
            mobile,
            searchParams.get("redirect_uri"),
            mapAppToUrl("Connect-connected"),
            lang,
            groupId,
            searchParams.get("registerEquipment"),
            isharrisbruno() ? "HB" : "MGI"
          );

          if (response === "Verification") {
            toast.success(t("need_verification"));
            setRegistered("verification");
          } else {
            toast.success(t("Sent_email") + email);
            setRegistered("verified");
          }
        } catch (e) {
          setRegistered(undefined);
          if (e instanceof Response && e.status === 409) {
            toast.error(t("email_used"));
          } else {
            toast.error("Server error");
          }
        } finally {
          setIsLoading(false);
        }
      }
    },
    [
      address,
      backend.connexion,
      company,
      confirmPassword,
      email,
      firstName,
      groupId,
      i18n.language,
      lastName,
      mobile,
      password,
      phone,
      searchParams,
      selectedCountry,
      t,
      zipcode,
    ]
  );

  const loginText = t("login_text");

  const [isShownCheckboxes, setIsShownCheckboxes] = useState<boolean>(false);

  const [usedLang, setUsedLang] = useState(i18n.language.split("-")[0]);

  useEffect(() => {
    const langFromPrecApp = searchParams.get("lang");
    if (langFromPrecApp !== null && langFromPrecApp !== undefined)
      i18n.changeLanguage(langFromPrecApp);
    setUsedLang(i18n.language.split("-")[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [showSelectedLang, setShowSelectLang] = useState(false);
  const showLanguage = () => {
    setShowSelectLang((showSelectedLang) => !showSelectedLang);
  };

  const changeLangInternal = (lang: AppLanguage) => {
    setUsedLang(lang);
    i18n.changeLanguage(lang);
    changeLang(lang);
  };

  const params = new URLSearchParams(document.location.search);
  const companyParam = params.get("company");
  const firstNameParam = params.get("firstName");
  const lastNameParam = params.get("lastName");
  const emailParam = params.get("email");
  const zipcodeParam = params.get("postcode");
  const countryParam = params.get("country");
  const phoneParam = params.get("phone");
  const addressParam = params.get("address");

  const createaccount2 = t("createaccount2");
  const refDivInside = useRef<HTMLDivElement>(null);
  useOutsideClick(refDivInside, () => {
    isShownCheckboxes && setIsShownCheckboxes(false);
  });

  useEffect(() => {
    groupId !== undefined && companyParam && setCompany(companyParam);
    groupId !== undefined && firstNameParam && setFirstName(firstNameParam);
    groupId !== undefined && lastNameParam && setLastName(lastNameParam);
    groupId !== undefined && emailParam && setEmail(emailParam);
    groupId !== undefined && zipcodeParam && setZipcode(zipcodeParam);
    groupId !== undefined && countryParam && setSelectedCountry(countryParam);
    groupId !== undefined && phoneParam && setPhone(phoneParam);
    groupId !== undefined && addressParam && setAddress(addressParam);
  }, [
    addressParam,
    companyParam,
    countryParam,
    emailParam,
    firstNameParam,
    groupId,
    lastNameParam,
    phoneParam,
    zipcodeParam,
  ]);

  const isRegisterCreation = searchParams.get("redirect_uri") === "register";

  return (
    <>
      <div
        className={classNames(
          registered === "verified"
            ? styles.littlezonemodal
            : registered === "verification"
            ? styles.littlezonemodal
            : styles.zonemodal
        )}
        data-theme={"dark"}
      >
        <div className={classNames(styles.modalcachemobile)}></div>
        <div className={classNames(styles.contactsupportzone)}>
          <a
            href={
              process.env[
                isharrisbruno()
                  ? "REACT_APP_CONNECT_URL_HB"
                  : "REACT_APP_CONNECT_URL"
              ] + "support"
            }
            target="_blank"
            rel="noreferrer"
          >
            <p>{t("need_help")}</p>
          </a>
        </div>
        <div>
          <div className={classNames(styles.changelanguagezone)}>
            <div className={classNames(styles.logomgi)}>
              <img src={logomgilittle} alt="Logo mgi connect" />
            </div>
            <button
              onClick={showLanguage}
              className={classNames(styles.changelanguage)}
            >
              {usedLang}
              <div
                className={classNames(
                  styles.button,
                  !showSelectedLang ? "" : styles.view
                )}
              >
                {usedLang === "fr" ? (
                  <p onClick={() => changeLangInternal("en")}>En</p>
                ) : (
                  <p onClick={() => changeLangInternal("fr")}>Fr</p>
                )}
              </div>
            </button>
          </div>
        </div>
        <div
          className={classNames(
            registered === "verified" ? styles.littlemodal : styles.modal,
            registered === "verification" ? styles.littlemodal : styles.modal
          )}
        >
          <div className={classNames(styles.rightzone)}>
            {registered === "verified" ? (
              <form>
                <div className={classNames(styles.form, styles.littleform)}>
                  <div
                    className={classNames(
                      styles.titleform,
                      styles.littletitleform
                    )}
                  >
                    <div className={classNames(styles.logozoneconnect)}>
                      {isharrisbruno() ? (
                        <img src={logoconnecthb} alt="Logo Harris & Bruno" />
                      ) : (
                        <img src={logoconnectgriscomplet} alt="Logo connect" />
                      )}
                    </div>
                    <div className={classNames(styles.separationzone)}>
                      <div className={classNames(styles.separation)}></div>
                    </div>
                  </div>

                  <div className={classNames(styles.inputzone)}>
                    <div className={classNames(styles.inputzone, "col-12")}>
                      <p
                        className={classNames(
                          styles.textlittle,
                          styles.marginbot15
                        )}
                      >
                        {isharrisbruno()
                          ? t("createAccount.successhb")
                          : t("createAccount.success")}
                      </p>
                      <p
                        className={classNames(
                          styles.textlittle,
                          styles.marginbot30
                        )}
                      >
                        {t("createAccount.success_text")}
                      </p>
                      <p
                        className={classNames(
                          styles.textlittle,
                          styles.emailunderline
                        )}
                      >
                        {email}
                      </p>
                    </div>
                  </div>
                </div>
              </form>
            ) : registered === "verification" ? (
              <form>
                <div className={classNames(styles.form, styles.littleform)}>
                  <div
                    className={classNames(
                      styles.titleform,
                      styles.littletitleform
                    )}
                  >
                    {/* <h2>Success</h2> */}
                    <div className={classNames(styles.logozoneconnect)}>
                      {isharrisbruno() ? (
                        <img src={logoconnecthb} alt="Logo Harris & Bruno" />
                      ) : (
                        <img src={logoconnectgriscomplet} alt="Logo connect" />
                      )}
                    </div>
                    <div className={classNames(styles.separationzone)}>
                      <div className={classNames(styles.separation)}></div>
                    </div>
                    {/* <h2>{t("createAccount.verification")}</h2> */}
                  </div>

                  <div className={classNames(styles.inputzone)}>
                    <div className={classNames(styles.inputzone, "col-12")}>
                      <p className={classNames(styles.textlittle)}>
                        {isharrisbruno()
                          ? t("createAccount.verificationhb")
                          : t("createAccount.verification")}
                      </p>
                      <p className={classNames(styles.textlittle)}>
                        {t("createAccount.verification_text")}
                        <br />
                        {t("createAccount.verification_text2")}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className={classNames(styles.btnzone, styles.verifbtnzone)}
                >
                  <div
                    className={classNames(
                      styles.submit,
                      isLoading && styles.disabled
                    )}
                  >
                    <input
                      className={classNames(styles.inputSubmit)}
                      value={loginText}
                      type="submit"
                      onClick={(e) => {
                        e.preventDefault();
                        auth.login();
                      }}
                    />
                  </div>
                </div>
              </form>
            ) : (
              <form action="#">
                <div className={classNames(styles.form)}>
                  <div className={classNames(styles.titleform)}>
                    <div className={classNames(styles.logozoneconnect)}>
                      {isharrisbruno() ? (
                        <img src={logoconnecthb} alt="Logo Harris & Bruno" />
                      ) : (
                        <img src={logoconnectgriscomplet} alt="Logo connect" />
                      )}
                    </div>
                    {isharrisbruno() ? (
                      <h2>
                        {t("createtext1hb")} <br /> {t("createtext2")}
                      </h2>
                    ) : (
                      <h2>
                        {t("createtext1")} <br /> {t("createtext2")}
                      </h2>
                    )}
                    <div className={classNames(styles.separation)}></div>
                  </div>
                  <div className={classNames(styles.inputzone)}>
                    <div className={classNames(styles.inputzone, "col-6")}>
                      <label htmlFor="name">
                        {t("name")}{" "}
                        <span className={classNames(styles.redtext)}>*</span>
                      </label>
                      <input
                        required
                        type="text"
                        name="lastName"
                        value={lastName}
                        id="lastName"
                        onChange={(e) => setLastName(e.target.value)}
                        ref={lastNameRef}
                        // placeholder="Nom"
                      />
                    </div>
                    <div className={classNames(styles.inputzone, "col-6")}>
                      <label htmlFor="prenom">
                        {t("Prénom")}{" "}
                        <span className={classNames(styles.redtext)}>*</span>
                      </label>
                      <input
                        required
                        type="text"
                        name="firstName"
                        value={firstName}
                        id="firstName"
                        onChange={(e) => setFirstName(e.target.value)}
                        ref={firstNameRef}
                        // placeholder="Prenom"
                      />
                    </div>
                    <div className={classNames(styles.inputzone, "col-12")}>
                      <label htmlFor="mail">
                        {t("mailadd")}{" "}
                        <span className={classNames(styles.redtext)}>*</span>
                      </label>
                      <input
                        // placeholder="example@gmail.com"
                        required
                        type="email"
                        name="email"
                        value={email}
                        id="email"
                        onChange={(e) => setEmail(e.target.value)}
                        ref={emailRef}
                      />
                    </div>

                    <div className={classNames(styles.inputzone, "col-6")}>
                      <label htmlFor="password">
                        {t("Password2")}{" "}
                        <span className={classNames(styles.redtext)}>*</span>
                        <span
                          className={classNames(styles.span_eye)}
                          onClick={showPasswordCallback}
                        >
                          <img
                            src={!showPassword ? eye : eyeClosed}
                            alt="eye password"
                            className={classNames(styles.password_eye)}
                          />
                        </span>
                      </label>
                      <input
                        ref={passwordRef}
                        required
                        // placeholder="Password"
                        type={showPassword ? "text" : "password"}
                        name="password"
                        id="password"
                        autoComplete="new-password"
                        onChange={(e) => {
                          setPassword(e.target.value);
                          if (e.target.value.length < 8) {
                            passwordRef?.current.setCustomValidity(
                              t("at_least_password")
                            );
                            passwordRef.current?.reportValidity();
                          } else {
                            passwordRef?.current.setCustomValidity("");
                          }
                        }}
                      />
                    </div>
                    <div className={classNames(styles.inputzone, "col-6")}>
                      <label htmlFor="confirmPassword">
                        {t("confirmPassword")}{" "}
                        <span className={classNames(styles.redtext)}>*</span>
                        <span
                          className={classNames(styles.span_eye)}
                          onClick={showPasswordCallback}
                        >
                          <img
                            src={!showPassword ? eye : eyeClosed}
                            alt="eye password"
                            className={classNames(styles.password_eye)}
                          />
                        </span>
                      </label>
                      <input
                        ref={confirmPasswordRef}
                        required
                        // placeholder="Confirm Password"
                        type={showPassword ? "text" : "password"}
                        name="confirmPassword"
                        id="confirmPassword"
                        autoComplete="new-password"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                      />
                    </div>
                    <div className={classNames(styles.inputzone, "col-6")}>
                      <label htmlFor="entreprise">
                        {t("Entreprise2")}{" "}
                        <span className={classNames(styles.redtext)}>*</span>
                      </label>
                      <input
                        required
                        // placeholder="Company"
                        type="text"
                        name="company"
                        id="company"
                        value={company}
                        disabled={companyExist}
                        onChange={(e) => setCompany(e.target.value)}
                        ref={companyRef}
                      />
                    </div>
                    <div className={classNames(styles.inputzone, "col-6")}>
                      <label htmlFor="entreprise">
                        {t("Address")}{" "}
                        <span className={classNames(styles.redtext)}>*</span>
                      </label>
                      <input
                        required
                        // placeholder="Company"
                        type="text"
                        name="address"
                        id="address"
                        value={address}
                        disabled={addressExist}
                        onChange={(e) => setAddress(e.target.value)}
                        ref={addressRef}
                      />
                    </div>
                    <div className={classNames(styles.inputzone, "col-6")}>
                      <label htmlFor="country">
                        {t("zipcode")}{" "}
                        <span className={classNames(styles.redtext)}>*</span>
                      </label>

                      <input
                        id="zipcode"
                        name="zipcode"
                        type="text"
                        required
                        value={zipcode}
                        disabled={postCodeExist}
                        onChange={(e) => setZipcode(e.target.value)}
                        ref={zipcodeRef}
                      />
                    </div>
                    <div className={classNames(styles.inputzone, "col-6")}>
                      <label htmlFor="country">
                        {t("Country2")}{" "}
                        <span className={classNames(styles.redtext)}>*</span>
                      </label>

                      <input
                        list="countries"
                        id="country"
                        name="country"
                        type="text"
                        disabled={countryExist}
                        required
                        onChange={(e) => setSelectedCountry(e.target.value)}
                        value={selectedCountry}
                        ref={countryRef}
                      />
                      <datalist id="countries">
                        {countries.map((country, id) => (
                          <option key={id} value={country}>
                            {country}
                          </option>
                        ))}
                      </datalist>
                    </div>
                    <div className={classNames(styles.inputzone, "col-6")}>
                      <label htmlFor="phone">
                        {t("phone")}{" "}
                        <span className={classNames(styles.redtext)}>*</span>
                      </label>

                      <input
                        id="phone"
                        name="phone"
                        type="text"
                        required
                        disabled={phoneExist}
                        onChange={(e) => setPhone(e.target.value)}
                        value={phone}
                        ref={phoneRef}
                      />
                    </div>
                    <div className={classNames(styles.inputzone, "col-6")}>
                      <label htmlFor="mobile">
                        {t("mobile")}{" "}
                        <span className={classNames(styles.redtext)}>*</span>
                      </label>
                      <input
                        id="mobile"
                        name="mobile"
                        type="text"
                        required
                        disabled={mobilePhoneExist}
                        value={mobile}
                        onChange={(e) => setMobile(e.target.value)}
                        ref={mobileRef}
                      />
                    </div>
                    <div
                      className={classNames(
                        styles.mandatoryFields,
                        styles.inputzone
                      )}
                    >
                      <span className={classNames(styles.redtext)}>* </span> :{" "}
                      {t("mandatory")}
                    </div>
                  </div>
                  {/* La div du dessous apparait quand un client arrive via un code entreprise */}
                  {isRegisterCreation && (
                    <div className={classNames(styles.codecontactsupport)}>
                      <p>
                        {t("codecontactsupport")}
                        <br />
                        {t("codecontactsupport2")}&nbsp;
                        <NavLink to={"/support"} target="_blank">
                          {t("clickhere")}
                        </NavLink>
                      </p>
                    </div>
                  )}
                </div>

                {/* La class btnzonelittle apparait quand un client arrive via un code entreprise */}
                <div
                  className={classNames(
                    styles.btnzone,
                    isRegisterCreation && styles.btnzonelittle
                  )}
                >
                  <div className={classNames(styles.twobtn)}>
                    <div
                      className={classNames(styles.compte)}
                      onClick={() => !auth.authenticated && auth.login()}
                    >
                      <p>{t("alraidyaccount")}</p>
                    </div>

                    <div
                      className={classNames(
                        styles.submit,
                        isLoading && styles.disabled
                      )}
                    >
                      <input
                        className={classNames(styles.inputSubmit)}
                        type="submit"
                        value={createaccount2}
                        onClick={(e) => {
                          !isLoading && handleCreateUser(e);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
      <div
        data-theme={"dark"}
        className={classNames(
          styles.padding,
          styles.float_left,
          styles.heightbottom,
          "col-12"
        )}
      >
        <a target="_blank" rel="noreferrer" href={t("CGUlink")}>
          {t("CGU")}
        </a>
      </div>
    </>
  );
};
export default CreateAccount;
