import { ThemeType, useAuth, useUser } from "@mgi-labs/mgi-id";
import classNames from "classnames";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import logoconnect from "../images/logo/logoconnectmgi.svg";
import logoconnecthb from "../images/logo/logoconnectmgihb.svg";
import logomgilittle from "../images/logo/logomgilittle.svg";
import { hasInvitationKmAccess } from "./Domain/hasKmAccess";
import styles from "./styles/menu.module.scss";
import { useBackend } from "../backend/BackendProvider";
import { isharrisbruno } from "./Domain/checkharrisbruno";

const Mgiconnect = ({
  theme,
  handleUpdateUserTheme,
}: {
  theme: ThemeType;
  handleUpdateUserTheme: () => void;
}) => {
  const { t } = useTranslation();

  const { auth } = useAuth();
  const user = useUser();
  const [openOrganisation, setOpenOrganisation] = useState<boolean>(false);

  const backend = useBackend();

  const realPack =
    backend.limitation.packType !== "no" &&
    backend.limitation.packType !== "beta";

  return (
    <div data-theme={"dark"} className={classNames(styles.menu)}>
      <div className={classNames(styles.container)}>
        <div className={classNames(styles.logocontainer)}>
          <div className={classNames(styles.logomgi)}>
            {isharrisbruno() ? (
              <img src={logoconnecthb} alt="Logo mgi connect hb" />
            ) : (
              <img src={logoconnect} alt="Logo mgi connect" />
            )}
          </div>
        </div>
        <div className={classNames(styles.logomgicontainer)}>
          <div className={classNames(styles.logomgibot)}>
            <img src={logomgilittle} alt="MGI" />
            <p>Digital Technology </p>
          </div>
        </div>

        <div className={classNames(styles.container_list)}>
          <div className={classNames(styles.list1)}>
            <ul>
              {
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    isActive
                      ? isharrisbruno()
                        ? styles.hbactive
                        : styles.active
                      : undefined
                  }
                >
                  <li className={classNames(styles.li1)}>
                    <div
                      className={classNames(
                        styles.lilith,
                        isharrisbruno() ? styles.hblilith : ""
                      )}
                    >
                      <div className={classNames(styles.svgzone)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24px"
                          viewBox="0 0 24 24"
                          width="24px"
                          fill="#FFFFFF"
                        >
                          <path d="M0 0h24v24H0z" fill="none" />
                          <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
                        </svg>
                      </div>
                      <div className={classNames(styles.text_select)}>
                        <p>{t("Home")}</p>
                      </div>
                    </div>
                  </li>
                </NavLink>
              }

              {auth.authenticated && (
                <NavLink
                  to="/applications"
                  className={({ isActive }) =>
                    isActive
                      ? isharrisbruno()
                        ? styles.hbactive
                        : styles.active
                      : undefined
                  }
                >
                  <li className={classNames(styles.li1)}>
                    <div
                      className={classNames(
                        styles.lilith,
                        isharrisbruno() ? styles.hblilith : ""
                      )}
                    >
                      <div className={classNames(styles.svgzone)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24px"
                          viewBox="0 0 24 24"
                          width="24px"
                          fill="#FFFFFF"
                        >
                          <path d="M0 0h24v24H0z" fill="none" />
                          <path d="M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm6-10v4h4V4h-4zm-6 4h4V4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z" />
                        </svg>
                      </div>
                      <div className={classNames(styles.text_select)}>
                        <p>Applications</p>
                      </div>
                    </div>
                  </li>
                </NavLink>
              )}

              {auth.authenticated && (
                <NavLink
                  to="/profil"
                  className={({ isActive }) =>
                    isActive
                      ? isharrisbruno()
                        ? styles.hbactive
                        : styles.active
                      : undefined
                  }
                >
                  <li className={classNames(styles.li1)}>
                    <div
                      className={classNames(
                        styles.lilith,
                        isharrisbruno() ? styles.hblilith : ""
                      )}
                    >
                      <div className={classNames(styles.svgzone)}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="24px"
                          viewBox="0 0 24 24"
                          width="24px"
                          fill="#FFFFFF"
                        >
                          <path d="M0 0h24v24H0z" fill="none" />
                          <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
                        </svg>
                      </div>
                      <div className={classNames(styles.text_select)}>
                        <p>{t("My_Profile")}</p>
                      </div>
                    </div>
                  </li>
                </NavLink>
              )}

              {/*
              Mon profil avec paiement
              <li className={classNames(styles.li1)}>
                <div
                  className={classNames(styles.lilith)}
                  onClick={() => setOpenProfile(!openProfile)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="#FFFFFF"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
                  </svg>

                  <div className={classNames(styles.text_select)}>
                    <p>{t("My_Profile")}</p>
                    <img
                      className={classNames(openProfile && styles.fliparrow)}
                      src={arrow_select}
                      alt=""
                    />
                  </div>
                </div>
                <ul className={classNames(!openProfile && styles.openMenu)}>
                  <NavLink to="/profil" activeClassName={styles.active}>
                    <li className={classNames(styles.li2)}>
                      <p>
                        {t("Personal_Information1")} <br />{" "}
                        {t("Personal_Information2")}
                      </p>
                    </li>
                  </NavLink>
                  <NavLink to="/password" activeClassName={styles.active}>
                    <li className={classNames(styles.li2)}>
                      <p>{t("Password")}</p>
                    </li>
                  </NavLink>
                  <li
                    className={classNames(styles.li2)}
                    style={{ display: "none" }}
                  >
                    <p>{t("Methods_of_payment")}</p>
                  </li>
                </ul>
              </li> */}
              {auth.authenticated && (
                <NavLink
                  to="/workteam"
                  className={({ isActive }) =>
                    isActive
                      ? isharrisbruno()
                        ? styles.hbactive
                        : styles.active
                      : undefined
                  }
                >
                  <li className={classNames(styles.li1)}>
                    <div
                      className={classNames(
                        styles.lilith,
                        isharrisbruno() ? styles.hblilith : ""
                      )}
                      onClick={() => setOpenOrganisation(!openOrganisation)}
                    >
                      <svg
                        className={classNames(styles.svgzone)}
                        xmlns="http://www.w3.org/2000/svg"
                        enableBackground="new 0 0 24 24"
                        height="24px"
                        viewBox="0 0 24 24"
                        width="24px"
                        fill="#FFFFFF"
                      >
                        <rect fill="none" height="24" width="24" />
                        <g>
                          <path d="M12,12.75c1.63,0,3.07,0.39,4.24,0.9c1.08,0.48,1.76,1.56,1.76,2.73L18,18H6l0-1.61c0-1.18,0.68-2.26,1.76-2.73 C8.93,13.14,10.37,12.75,12,12.75z M4,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C2,12.1,2.9,13,4,13z M5.13,14.1 C4.76,14.04,4.39,14,4,14c-0.99,0-1.93,0.21-2.78,0.58C0.48,14.9,0,15.62,0,16.43V18l4.5,0v-1.61C4.5,15.56,4.73,14.78,5.13,14.1z M20,13c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2C18,12.1,18.9,13,20,13z M24,16.43c0-0.81-0.48-1.53-1.22-1.85 C21.93,14.21,20.99,14,20,14c-0.39,0-0.76,0.04-1.13,0.1c0.4,0.68,0.63,1.46,0.63,2.29V18l4.5,0V16.43z M12,6c1.66,0,3,1.34,3,3 c0,1.66-1.34,3-3,3s-3-1.34-3-3C9,7.34,10.34,6,12,6z" />
                        </g>
                      </svg>
                      <div className={classNames(styles.text_select)}>
                        <p>{t(realPack ? "User_management1" : "Members")}</p>
                      </div>
                    </div>
                  </li>
                </NavLink>
              )}

              {/* {auth.authenticated &&
                backend.limitation.packType !== "no" &&
                backend.limitation.packType !== "beta" && (
                  <NavLink
                    to="/extensions"
                    className={({ isActive }) =>
                      isActive ? styles.active : undefined
                    }
                  >
                    <li className={classNames(styles.li1)}>
                      <div
                        className={classNames(styles.lilith)}
                        onClick={() => setOpenOrganisation(!openOrganisation)}
                      >
                        <svg
                          className={classNames(styles.svgzone)}
                          xmlns="http://www.w3.org/2000/svg"
                          width="23.421"
                          height="25"
                          viewBox="0 0 23.421 25"
                        >
                          <path
                            id="shopping"
                            d="M74.008,146.118v-3.665H70.313v-1.788h3.695V137H75.8v3.665h3.665v1.788H75.8v3.665ZM68.787,162a2.051,2.051,0,0,1-1.513-.632,2.083,2.083,0,0,1-.626-1.52,2.051,2.051,0,0,1,.632-1.513,2.083,2.083,0,0,1,1.52-.626,2.051,2.051,0,0,1,1.513.632,2.083,2.083,0,0,1,.626,1.52,2.051,2.051,0,0,1-.632,1.513A2.083,2.083,0,0,1,68.787,162Zm12.038,0a2.051,2.051,0,0,1-1.513-.632,2.083,2.083,0,0,1-.626-1.52,2.051,2.051,0,0,1,.632-1.513,2.083,2.083,0,0,1,1.52-.626,2.051,2.051,0,0,1,1.513.632,2.083,2.083,0,0,1,.626,1.52,2.051,2.051,0,0,1-.632,1.513A2.083,2.083,0,0,1,80.826,162Zm-12.032-6.168a2.03,2.03,0,0,1-1.818-3.069l1.818-3.307-4.47-9.505H62v-1.788h3.456l5.066,10.846h8.7l4.648-8.343,1.549.834-4.559,8.254a2.039,2.039,0,0,1-.735.77,2.068,2.068,0,0,1-1.082.273H70.1l-1.847,3.248h14.72v1.788Z"
                            transform="translate(-62 -137)"
                          />
                        </svg>

                        <div className={classNames(styles.text_select)}>
                          <p>Extensions</p>
                        </div>
                      </div>
                    </li>
                  </NavLink>
                )} */}
              {/* 
              <li className={classNames(styles.li1)}>
                <div
                  className={classNames(styles.lilith)}
                  onClick={() => setOpenApplication(!openApplication)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="#FFFFFF"
                  >
                    <path d="M0 0h24v24H0z" fill="none" />
                    <path d="M4 8h4V4H4v4zm6 12h4v-4h-4v4zm-6 0h4v-4H4v4zm0-6h4v-4H4v4zm6 0h4v-4h-4v4zm6-10v4h4V4h-4zm-6 4h4V4h-4v4zm6 6h4v-4h-4v4zm0 6h4v-4h-4v4z" />
                  </svg>
                  <div className={classNames(styles.text_select)}>
                    <p>Applications</p>
                    <img
                      className={classNames(
                        openApplication && styles.fliparrow
                      )}
                      src={arrow_select}
                      alt=""
                    />
                  </div>
                </div>
                <ul className={classNames(!openApplication && styles.openMenu)}>
                  <li className={classNames(styles.li2)}>
                  
                      href="https://approve.mgi-labs.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Approve
                    </a>
                  </li>
                  <li className={classNames(styles.li2)}>
             
                    <a
                      href="https://vb-backoffice-staging.osc-fr1.scalingo.io/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Catalog
                    </a>
                  </li>
                  <li className={classNames(styles.li2)}>
               
                    <a
                      href="https://dashboard.mgi-labs.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Dashboard
                    </a>
                  </li>
                  <li className={classNames(styles.li2)}>
                   
                    <a
                      href="https://roi-alphajet.osc-fr1.scalingo.io/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      ROI
                    </a>
                  </li>
                </ul>
              </li> */}

              <NavLink
                to="/support"
                className={({ isActive }) =>
                  isActive
                    ? isharrisbruno()
                      ? styles.hbactive
                      : styles.active
                    : undefined
                }
              >
                <li className={classNames(styles.li1)}>
                  <div
                    className={classNames(
                      styles.lilith,
                      isharrisbruno() ? styles.hblilith : ""
                    )}
                  >
                    <div className={classNames(styles.svgzone)}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 0 23 23"
                        width="24px"
                        fill="#000000"
                      >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M11.5 2C6.81 2 3 5.81 3 10.5S6.81 19 11.5 19h.5v3c4.86-2.34 8-7 8-11.5C20 5.81 16.19 2 11.5 2zm1 14.5h-2v-2h2v2zm0-3.5h-2c0-3.25 3-3 3-5 0-1.1-.9-2-2-2s-2 .9-2 2h-2c0-2.21 1.79-4 4-4s4 1.79 4 4c0 2.5-3 2.75-3 5z" />
                      </svg>
                    </div>
                    <div className={classNames(styles.text_select)}>
                      <p>{t("Support")}</p>
                    </div>
                  </div>
                </li>
              </NavLink>
              <div className={classNames(styles.separationbar)}></div>
              {auth.authenticated &&
                hasInvitationKmAccess(user?.producer_team_uuid) && (
                  <NavLink
                    to="/shareaccount"
                    className={({ isActive }) =>
                      isActive
                        ? isharrisbruno()
                          ? styles.hbactive
                          : styles.active
                        : undefined
                    }
                  >
                    <li className={classNames(styles.li1)}>
                      <div
                        className={classNames(
                          styles.lilith,
                          isharrisbruno() ? styles.hblilith : ""
                        )}
                      >
                        <div className={classNames(styles.svgzone)}>
                          <svg
                            className={classNames(styles.path)}
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="16"
                            viewBox="0 0 22 16"
                          >
                            <g
                              id="Groupe_2"
                              data-name="Groupe 2"
                              transform="translate(-1 -4)"
                            >
                              <path
                                id="Tracé_7"
                                data-name="Tracé 7"
                                d="M20,9V6H18V9H15v2h3v3h2V11h3V9ZM9,12A4,4,0,1,0,5,8,4,4,0,0,0,9,12ZM9,6A2,2,0,1,1,7,8,2.006,2.006,0,0,1,9,6Zm6.39,8.56a13.867,13.867,0,0,0-12.78,0A2.971,2.971,0,0,0,1,17.22V20H17V17.22A2.971,2.971,0,0,0,15.39,14.56ZM15,18H3v-.78a.971.971,0,0,1,.52-.88A12.108,12.108,0,0,1,9,15a12.108,12.108,0,0,1,5.48,1.34.971.971,0,0,1,.52.88Z"
                                fill="#fff"
                              />
                            </g>
                          </svg>
                        </div>

                        <div className={classNames(styles.text_select)}>
                          <p>{t("KMadduser")}</p>
                        </div>
                      </div>
                    </li>
                  </NavLink>
                )}
              {auth.authenticated &&
                hasInvitationKmAccess(user?.producer_team_uuid) && (
                  <NavLink
                    to="/KMAccountTable"
                    className={({ isActive }) =>
                      isActive
                        ? isharrisbruno()
                          ? styles.hbactive
                          : styles.active
                        : undefined
                    }
                  >
                    <li className={classNames(styles.li1)}>
                      <div
                        className={classNames(
                          styles.lilith,
                          isharrisbruno() ? styles.hblilith : ""
                        )}
                      >
                        <div className={classNames(styles.svgzone)}>
                          <svg
                            className={classNames(styles.path)}
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="14"
                            viewBox="0 0 22 14"
                          >
                            <path
                              id="Tracé_6"
                              data-name="Tracé 6"
                              d="M16.5,13A11.987,11.987,0,0,0,12,14a11.8,11.8,0,0,0-4.5-1C5.33,13,1,14.08,1,16.25V19H23V16.25C23,14.08,18.67,13,16.5,13Zm-4,4.5H2.5V16.25c0-.54,2.56-1.75,5-1.75s5,1.21,5,1.75Zm9,0H14V16.25a1.819,1.819,0,0,0-.52-1.22,9.647,9.647,0,0,1,3.02-.53c2.44,0,5,1.21,5,1.75ZM7.5,12A3.5,3.5,0,1,0,4,8.5,3.5,3.5,0,0,0,7.5,12Zm0-5.5a2,2,0,1,1-2,2A2.006,2.006,0,0,1,7.5,6.5Zm9,5.5A3.5,3.5,0,1,0,13,8.5,3.5,3.5,0,0,0,16.5,12Zm0-5.5a2,2,0,1,1-2,2A2.006,2.006,0,0,1,16.5,6.5Z"
                              transform="translate(-1 -5)"
                              fill="#fff"
                            />
                          </svg>
                        </div>

                        <div className={classNames(styles.text_select)}>
                          <p>{t("KMAccountsTable.my_clients")}</p>
                        </div>
                      </div>
                    </li>
                  </NavLink>
                )}

              <div className={classNames(styles.whitedarkmode)}>
                <li className={classNames(styles.li1)}>
                  <div
                    className={classNames(
                      styles.lilith,
                      isharrisbruno() ? styles.hblilith : ""
                    )}
                    onClick={handleUpdateUserTheme}
                  >
                    {theme === "light" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                      >
                        <path
                          id="dark_mode_FILL0_wght400_GRAD0_opsz48"
                          d="M24,42a17.359,17.359,0,0,1-12.75-5.25A17.359,17.359,0,0,1,6,24a17.359,17.359,0,0,1,5.25-12.75A17.359,17.359,0,0,1,24,6q.4,0,.85.025T26,6.1a10.923,10.923,0,0,0-2.8,3.95,12.512,12.512,0,0,0-1,4.95,10.415,10.415,0,0,0,3.15,7.65A10.415,10.415,0,0,0,33,25.8a13.393,13.393,0,0,0,4.95-.925A11.052,11.052,0,0,0,41.9,22.3q.05.6.075.975T42,24a17.359,17.359,0,0,1-5.25,12.75A17.359,17.359,0,0,1,24,42Zm0-3a14.382,14.382,0,0,0,9.5-3.375A14.168,14.168,0,0,0,38.55,27.7a12.5,12.5,0,0,1-2.675.825A15.138,15.138,0,0,1,33,28.8a13.308,13.308,0,0,1-9.775-4.025A13.308,13.308,0,0,1,19.2,15a14.438,14.438,0,0,1,.25-2.575,18.782,18.782,0,0,1,.9-3.125,15.23,15.23,0,0,0-8.125,5.475A14.606,14.606,0,0,0,9,24a14.465,14.465,0,0,0,4.375,10.625A14.465,14.465,0,0,0,24,39ZM23.8,24.15Z"
                          transform="translate(-6 -6)"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                      >
                        <path
                          id="dark_mode_FILL0_wght400_GRAD0_opsz48"
                          d="M24,42a17.359,17.359,0,0,1-12.75-5.25A17.359,17.359,0,0,1,6,24a17.359,17.359,0,0,1,5.25-12.75A17.359,17.359,0,0,1,24,6q.4,0,.85.025T26,6.1a10.923,10.923,0,0,0-2.8,3.95,12.512,12.512,0,0,0-1,4.95,10.415,10.415,0,0,0,3.15,7.65A10.415,10.415,0,0,0,33,25.8a13.393,13.393,0,0,0,4.95-.925A11.052,11.052,0,0,0,41.9,22.3q.05.6.075.975T42,24a17.359,17.359,0,0,1-5.25,12.75A17.359,17.359,0,0,1,24,42Zm0-3a14.382,14.382,0,0,0,9.5-3.375A14.168,14.168,0,0,0,38.55,27.7a12.5,12.5,0,0,1-2.675.825A15.138,15.138,0,0,1,33,28.8a13.308,13.308,0,0,1-9.775-4.025A13.308,13.308,0,0,1,19.2,15a14.438,14.438,0,0,1,.25-2.575,18.782,18.782,0,0,1,.9-3.125,15.23,15.23,0,0,0-8.125,5.475A14.606,14.606,0,0,0,9,24a14.465,14.465,0,0,0,4.375,10.625A14.465,14.465,0,0,0,24,39ZM23.8,24.15Z"
                          transform="translate(-6 -6)"
                        />
                      </svg>
                      // <svg
                      //   xmlns="http://www.w3.org/2000/svg"
                      //   width="44"
                      //   height="44"
                      //   viewBox="0 0 44 44"
                      // >
                      //   <path
                      //     id="light_mode_FILL0_wght400_GRAD0_opsz48"
                      //     d="M24,31a7,7,0,0,0,7-7,7,7,0,0,0-7-7,7,7,0,0,0-7,7,7,7,0,0,0,7,7Zm0,3A9.987,9.987,0,0,1,14,24,9.987,9.987,0,0,1,24,14,9.987,9.987,0,0,1,34,24,9.987,9.987,0,0,1,24,34ZM3.5,25.5a1.5,1.5,0,1,1,0-3h5a1.5,1.5,0,1,1,0,3Zm36,0a1.5,1.5,0,1,1,0-3h5a1.5,1.5,0,1,1,0,3ZM24,10a1.454,1.454,0,0,1-1.5-1.5v-5a1.5,1.5,0,1,1,3,0v5A1.454,1.454,0,0,1,24,10Zm0,36a1.454,1.454,0,0,1-1.5-1.5v-5a1.5,1.5,0,1,1,3,0v5A1.454,1.454,0,0,1,24,46ZM12,14.1,9.15,11.3a1.383,1.383,0,0,1-.425-1.075A1.669,1.669,0,0,1,9.15,9.15a1.509,1.509,0,0,1,2.15,0L14.1,12a1.531,1.531,0,0,1,.4,1.05,1.357,1.357,0,0,1-.4,1,1.31,1.31,0,0,1-1.025.45A1.563,1.563,0,0,1,12,14.1ZM36.7,38.85,33.9,36a1.563,1.563,0,0,1-.4-1.075,1.31,1.31,0,0,1,.45-1.025,1.283,1.283,0,0,1,1-.45A1.439,1.439,0,0,1,36,33.9l2.85,2.8a1.383,1.383,0,0,1,.425,1.075,1.669,1.669,0,0,1-.425,1.075,1.509,1.509,0,0,1-2.15,0ZM33.9,14.1a1.45,1.45,0,0,1,0-2.1l2.8-2.85a1.383,1.383,0,0,1,1.075-.425,1.669,1.669,0,0,1,1.075.425,1.509,1.509,0,0,1,0,2.15L36,14.1a1.388,1.388,0,0,1-1.025.4,1.563,1.563,0,0,1-1.075-.4ZM9.15,38.85a1.509,1.509,0,0,1,0-2.15L12,33.9A1.485,1.485,0,1,1,14.1,36l-2.8,2.85a1.383,1.383,0,0,1-1.075.425A1.669,1.669,0,0,1,9.15,38.85ZM24,24Z"
                      //     transform="translate(-2 -2)"
                      //   />
                      // </svg>
                    )}

                    <div className={classNames(styles.btn_select)}>
                      {/* <p>{t(theme === "light" ? "darkmode" : "whitemode")}</p> */}
                      <div
                        className={classNames(
                          isharrisbruno() ? styles.bkgbtnhb : styles.bkgbtn,
                          theme === "light" ? styles.btnwhite : ""
                        )}
                      >
                        <div className={classNames(styles.btninterieur)}></div>
                      </div>
                    </div>
                  </div>
                </li>
              </div>
              {/* <li className={classNames(styles.li1)}>
                <div
                  className={classNames(styles.lilith)}
                  onClick={() => setOpenRessource(!openRessource)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    enableBackground="new 0 0 24 24"
                    height="24px"
                    viewBox="0 0 24 24"
                    width="24px"
                    fill="#FFFFFF"
                  >
                    <g>
                      <rect fill="none" height="24" width="24" />
                    </g>
                    <g>
                      <g />
                      <g>
                        <path d="M21,5c-1.11-0.35-2.33-0.5-3.5-0.5c-1.95,0-4.05,0.4-5.5,1.5c-1.45-1.1-3.55-1.5-5.5-1.5S2.45,4.9,1,6v14.65 c0,0.25,0.25,0.5,0.5,0.5c0.1,0,0.15-0.05,0.25-0.05C3.1,20.45,5.05,20,6.5,20c1.95,0,4.05,0.4,5.5,1.5c1.35-0.85,3.8-1.5,5.5-1.5 c1.65,0,3.35,0.3,4.75,1.05c0.1,0.05,0.15,0.05,0.25,0.05c0.25,0,0.5-0.25,0.5-0.5V6C22.4,5.55,21.75,5.25,21,5z M21,18.5 c-1.1-0.35-2.3-0.5-3.5-0.5c-1.7,0-4.15,0.65-5.5,1.5V8c1.35-0.85,3.8-1.5,5.5-1.5c1.2,0,2.4,0.15,3.5,0.5V18.5z" />
                        <g>
                          <path d="M17.5,10.5c0.88,0,1.73,0.09,2.5,0.26V9.24C19.21,9.09,18.36,9,17.5,9c-1.7,0-3.24,0.29-4.5,0.83v1.66 C14.13,10.85,15.7,10.5,17.5,10.5z" />
                          <path d="M13,12.49v1.66c1.13-0.64,2.7-0.99,4.5-0.99c0.88,0,1.73,0.09,2.5,0.26V11.9c-0.79-0.15-1.64-0.24-2.5-0.24 C15.8,11.66,14.26,11.96,13,12.49z" />
                          <path d="M17.5,14.33c-1.7,0-3.24,0.29-4.5,0.83v1.66c1.13-0.64,2.7-0.99,4.5-0.99c0.88,0,1.73,0.09,2.5,0.26v-1.52 C19.21,14.41,18.36,14.33,17.5,14.33z" />
                        </g>
                      </g>
                    </g>
                  </svg>
                  <div className={classNames(styles.text_select)}>
                    <p>Ressources</p>
                    <img
                      className={classNames(openRessource && styles.fliparrow)}
                      src={arrow_select}
                      alt=""
                    />
                  </div>
                </div>
                <ul className={classNames(!openRessource && styles.openMenu)}>
                  <li className={classNames(styles.li2)}>
                    <a
                      href="https://guidelines.mgi-labs.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Guidelines
                    </a>
                  </li>
                  <li
                    className={classNames(styles.li2)}
                    style={{ display: "none" }}
                  >
                    <p>Docs</p>
                  </li>
                  <li
                    className={classNames(styles.li2)}
                    style={{ display: "none" }}
                  >
                    <p>FAQ</p>
                  </li>
                </ul>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mgiconnect;
